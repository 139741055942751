
import { api } from '@/api';
import { IMainBanner } from '@/interfaces/banners';
import { ICreatureKind, IProductBrand, IProductsCategoryLight, IProductsList } from '@/interfaces/products';
import { dispatchGetNewsList } from '@/store/news/actions';
import { readNewsList } from '@/store/news/getters';
import { dispatchGetBrandsList, dispatchGetPopularKindsProductsList, dispatchGetPopularProductsCategoriesList, dispatchGetProductsByTagList, dispatchGetProductsViewHistoryList } from '@/store/products/actions';
import { getLocalSessionId } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import RecentlyViewedProducts from '@/components/Products/RecentlyViewedProducts.vue';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import { readProductsCategoriesList } from '@/store/products/getters';
import carousel from 'vue-owl-carousel';

@Component({
    components: {
      RecentlyViewedProducts,
      ProductGridCard,
      carousel,
    },
})

export default class HomePage extends Vue {
  public rating = 1;
  public isFavorite = false;
  public productPriceWarehouse: any = {
    choices: [],
  };
  public productPropertiesAndOptions: any = {
    choices: [],
  }
  public bannersList: IMainBanner[] = [];
  public popularCategoriesList: IProductsCategoryLight[] = [];
  public hitProductsList: IProductsList[] = [];
  public exclusiveProductsList: IProductsList[] = [];
  public promoProductsList: IProductsList[] = [];
  public productOfDayProductsList: IProductsList[] = [];
  public popularKindsProductsList: ICreatureKind[] = [];
  public brandsList: IProductBrand[] = [];
  // public brandsList = ['Advance', 'Brekkies', 'Stefanplast', 'Almo Nature', 'Flexi', 'Baldecchi', 'Doggy Dolly', 'Lindocat'];
  public categoriesColorsBg = ['bg-green', 'bg-orange', 'bg-blue', 'bg-pink', 'bg-green', 'bg-orange', 'bg-blue', 'bg-pink'];
  public loadingState: any = {
    page: true,
  };

  public async mounted() {
    // this.loadingState.list = true;
    try {
      const bannersList = await api.getMainBanners(this.$store.getters.token, {});
      this.bannersList = bannersList.data;
    } catch (e) {}
    await dispatchGetNewsList(this.$store, {rowQuery: `?page=1&per_page=10`});
    this.brandsList = await dispatchGetBrandsList(this.$store, {});
    this.hitProductsList = await dispatchGetProductsByTagList(this.$store, {data: {tag: 'hit'}, rowQuery: `?page=1&per_page=10`});
    this.exclusiveProductsList = await dispatchGetProductsByTagList(this.$store, {data: {tag: 'exclusive'}, rowQuery: `?page=1&per_page=10`});
    this.promoProductsList = await dispatchGetProductsByTagList(this.$store, {data: {tag: 'promo'}, rowQuery: `?page=1&per_page=10`});
    this.productOfDayProductsList = await dispatchGetProductsByTagList(this.$store, {data: {tag: 'product_of_day'}, rowQuery: `?page=1&per_page=10`});
    this.popularCategoriesList = await dispatchGetPopularProductsCategoriesList(this.$store,);
    this.popularKindsProductsList = await dispatchGetPopularKindsProductsList(this.$store, {});
    // this.loadingState.list = false;
    this.loadingState.page = false;
  }

  public handleChangeCatalog(codename) {
    this.$router.push(`/catalog/${codename}`);
  }

  public handleChangePopularKind(id) {
    this.$router.push(`/products/kinds/list/${id}`);
  }

  get productsList() {
      const productsList: any = [];
      // if (this.productPriceWarehouse && this.productPriceWarehouse) {
      // for (const [key, value] of Object.entries(this.productPriceWarehouse)) {
      //     // @ts-ignore
      //     if (value[0]) {
      //         productsList.push({
      //         // @ts-ignore
      //         id: value[0], label: value[1],
      //         });
      //     }
      // }
      // }
      return productsList;
  }

  get newsResponse() {
    return readNewsList(this.$store);
  }
  get categoriesResponse() {
    return readProductsCategoriesList(this.$store);
  }
}
